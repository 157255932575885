import axios from 'axios';
import {sha256} from "js-sha256";

const clientURL = {
    development: 'http://localhost:55550/',
    production: 'https://kolomafamily.com/'
}

function setPostData(data) {
    if (data == null) return ''
    if (typeof data === 'string') return data
    else return JSON.stringify(data)
}

function getApiData(dataPath, methodIsPOST, postData) {
    // console.info('API DATA REQUEST: ', dataPath, methodIsPOST, postData, isFormData)
    const apiUrl = clientURL[process.env.NODE_ENV] + 'get-data/'+ dataPath.replace(/\//g, '+')
    if (!methodIsPOST) return axios.get(apiUrl)
    else return axios.post(apiUrl, setPostData(postData), {responseType: 'json'})
}

function getImageSrc(image, dir) {
    return window.location.origin + '/' + dir + '/' + image
}

function addUniqueVisitor() {
    let uid = localStorage.getItem('kfuvid')
    if (!uid) {
        uid = sha256.create().update(new Date().getTime() + '-' + Math.floor(Math.random() * 10000)).hex()
        localStorage.setItem('kfuvid', uid)
    }
    axios.get(clientURL[process.env.NODE_ENV] + 'add-unique/' + uid, {
        headers: {
            'Authorization': uid
        }
    })
}

const api = {
    getSrc: getImageSrc,
    getData: getApiData,
    addUniqueVisitor: addUniqueVisitor
};

export default api;
