<template>
  <header>
    <div class="inner">
      <div class="left">
        <div class="kf-logo"><span><router-link to="/" class="kf-logo-a">KF</router-link></span></div>
        <span><router-link to="/">HOME</router-link></span>
        <div class="hr"></div>
        <span><router-link to="/blog">BLOG</router-link></span>
        <div class="hr"></div>
        <span><router-link to="/gallery">GALLERY</router-link></span>
        <div class="hr"></div>
        <span><router-link to="/list">LIST OF NEEDS</router-link></span>
        <div class="hr"></div>
        <span><router-link to="/contacts">CONTACTS</router-link></span>
        <div class="hr"></div>
        <span><a href="https://kolomafamily.wayforpay.shop/" target="_blank">SUPPORT</a></span>
        <div class="filter-container">
          <img src="../assets/img/flag_ua.svg" alt="flag ua" class="lang-filter" @click="changeLang" v-if="LANG === 'ua'">
          <img src="../assets/img/flag_en.svg" alt="flag uk" class="lang-filter" @click="changeLang" v-else>
        </div>
      </div>
      <div class="touchscreen-left">
        <svg ref="menu-btn" @click="mobileMenuOpened = !mobileMenuOpened" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 384.97 384.97" style="enable-background:new 0 0 384.97 384.97; fill: #ffffff;" xml:space="preserve">
<g>
	<g id="Menu">
		<path d="M12.03,84.212h360.909c6.641,0,12.03-5.39,12.03-12.03c0-6.641-5.39-12.03-12.03-12.03H12.03
			C5.39,60.152,0,65.541,0,72.182C0,78.823,5.39,84.212,12.03,84.212z"/>
    <path d="M372.939,180.455H12.03c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03
			S379.58,180.455,372.939,180.455z"/>
    <path d="M372.939,300.758H12.03c-6.641,0-12.03,5.39-12.03,12.03c0,6.641,5.39,12.03,12.03,12.03h360.909
			c6.641,0,12.03-5.39,12.03-12.03C384.97,306.147,379.58,300.758,372.939,300.758z"/>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
  <g>
	</g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
</svg>
        <div class="filter-container">
          <img src="../assets/img/flag_ua.svg" alt="flag ua" class="lang-filter" @click="changeLang" v-if="LANG === 'ua'">
          <img src="../assets/img/flag_en.svg" alt="flag uk" class="lang-filter" @click="changeLang" v-else>
        </div>
        <div class="kf-logo" @click="mobileMenuOpened = false"><span><router-link to="/" class="kf-logo-a">KF</router-link></span></div>
        <div class="mob-menu" :class="{'opened': mobileMenuOpened}" ref="menu">
          <span @click="mobileMenuOpened = false"><router-link to="/">HOME</router-link></span>
          <span @click="mobileMenuOpened = false"><router-link to="/blog">BLOG</router-link></span>
          <span @click="mobileMenuOpened = false"><router-link to="/gallery">GALLERY</router-link></span>
          <span @click="mobileMenuOpened = false"><router-link to="/list">LIST OF NEEDS</router-link></span>
<!--          <span @click="mobileMenuOpened = false"><router-link to="/support">SUPPORT</router-link></span>-->
          <span @click="mobileMenuOpened = false"><router-link to="/contacts">CONTACTS</router-link></span>
          <span @click="mobileMenuOpened = false"><a href="https://kolomafamily.wayforpay.shop/" target="_blank">SUPPORT</a></span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "Header",
  data() {
    return {
      mobileMenuOpened: false
    }
  },
  computed: {
    ...mapGetters([
        'LANG'
    ])
  },
  methods: {
    ...mapActions([
       'changeLang'
    ]),
    closeMenu() {
      this.mobileMenuOpened = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";
@import "../assets/scss/mixins";

header {
  overflow: visible;
  .inner {
    justify-content: space-between;

    .hr {
      height: 3rem;
      width: 2px;
      background-color: #ffffff;
      align-self: center;
    }

    .touchscreen-left {
      position: relative;

      .mob-menu {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: 100%;
        height: min-content;
        max-height: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow: hidden;
        transition: all .4s linear;
        background-color: #ffffff;
        padding: 20px 0;
        opacity: 0;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;

        span {
          color: rgba(255, 117, 24, .5);
          font-weight: bold;
        }

        &.opened {
          transition: all .4s linear;
          opacity: 1;
          max-height: 100vh;
        }
      }
      .filter-container {
        margin-right: auto;
      }
    }
    .left .filter-container {
      margin-left: auto;
      max-width: 50px;
    }
    .filter-container {
      align-self: center;

      .lang-filter {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: none;
        object-fit: fill;
        background-color: aquamarine;
        transition: $defaultTransition;

        @include hover-supported-cursor-pointer() {
          transition: $defaultTransition;
          transform: scale(1.1);
          box-shadow: 0 0 10px rgba(255,255,255,.7);
        }
      }
    }
  }
}

</style>
